import React from 'react'
import { Link } from 'react-router-dom'
const Transparency = () => {
  return (
    <div>
      <section className="pt-5 pb-5 mt-5 tokenized_gold ">
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-9 mb-4">
              <div className="main_title">
                <h2 className="h2 text-primary">Transparency</h2>
                <h3 className="h3 text-secondary">We provide an unrivaled level of reserve transparency, so that the community can always be confident that their digital assets are fully backed by Physical Gold.</h3>
                <ul className="text-center m-0 p-0">
                  <li><span>Quarterly audits by Custodian based in UAE</span></li>
                  <li><span>On-demand verification for an onboarded entity. </span></li>
                    <li><span>Subject to Service fees applicable and prior appointment is required.</span></li>
                </ul>
              </div>
            </div>

            <div className="col-12 mt-5 mb-4">
              <div className="row justify-content-center">
                <div className="col-lg-9 mb-4">
                  <div className="main_title">
                    <h2 className="h2 text-primary">CURRENT GOLD SUPPLY</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-5">
              <div className="col-lg-9">
                <div className="t-table_wrap">

                  <ul className="t-body">
                    <li className="d-flex flex-wrap w-100">
                      <span>Digital Gold</span>
                      <span>14,000</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Tokenized Gold</span>
                      <span>142,000</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Total</span>
                      <span>156,000</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

           <p>(check on <Link style={{color:"#EDB246"}} to="https://explorer.xinfin.network/tokens/xdc8f9920283470f52128bf11b0c14e798be704fd15"target='_blank'>XDC Network Explorer</Link>)</p>


            <div className="col-lg-3 col-md-6 col-6 mb-4">
              <div className="supply_box h-100 " >
                <div className="supply_icon text-center mb-2 ">
                <img src="assets/images/pdf_icon.svg"  class="img-fluid iconD" alt="pdf" />
                    <img src="assets/images/pdf_white.svg" class="img-fluid iconL" alt="pdf" />
                </div>
                <div className="supply_content text-center">
                  <h5 className="mb-3">Smart Contract Audit Report</h5>
                  <p>Explore Smart Contract Code at XDC Network explorer.</p>
                  <h6 className="mb-3">OCTOBER 6, 2023</h6>
                </div>
                <div className="supply_button text-center">
                  <Link to="/assets/pdf/ComtechGold_New_Audit.pdf" download target='_blank' className="btn btn-outline-primary">Download</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-4">
              <div className="supply_box h-100">
                <div className="supply_icon text-center mb-2">
                <img src="assets/images/edit_icon.svg" class="img-fluid iconD" alt="pdf" />
                    <img src="assets/images/edit_white.svg" class="img-fluid iconL" alt="pdf" />
                </div>
                <div className="supply_content text-center">
                  <h5 className="mb-3">On-demand verification</h5>
                  <p>On-demand verification of Gold reserve balances and accounts, provided by a UAE based custodian.</p>
                </div>
                <div className="supply_button text-center">
                  <Link to="/Contactus" className="btn btn-outline-primary">Request Now</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-4">
              <div className="supply_box h-100">
                <div className="supply_icon text-center mb-2">
                <img src="assets/images/pdf_icon.svg" class="img-fluid iconD" alt="pdf" />
                    <img src="assets/images/pdf_white.svg" class="img-fluid iconL" alt="pdf" />
                </div>
                <div className="supply_content text-center">
                  <h5 className="mb-3">Routine audit</h5>
                  <p>Regular Audit report from custodian.</p>
                  <h6 className="mb-3">March 08, 2023</h6>
                </div>
                <div className="supply_button text-center">
                  <Link to="/Routine" className="btn btn-outline-primary">Download</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-4">
              <div className="supply_box h-100">
                <div className="supply_icon text-center mb-2">
                <img src="assets/images/pdf_icon.svg" class="img-fluid iconD" alt="pdf" />
                    <img src="assets/images/pdf_white.svg" class="img-fluid iconL" alt="pdf" />
                </div>
                <div className="supply_content text-center">
                  <h5 className="mb-3">Shariah Certification</h5>
                  <p>Explore Smart Contract Code at XDC Network explorer.</p>
                  <h6 className="mb-3">13 August 2024</h6>
                </div>
                <div className="supply_button text-center">
                  <Link to="/assets/pdf/ComTech_Gold_Token_Shariah_Compliance_Cert.pdf" download target='_blank' className="btn btn-outline-primary">Download</Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default Transparency