import React, { useState } from 'react';
import { Link } from 'react-router-dom'
const Digitalgold = () => {
  const initialVisibleFeatures = 4;
  const [visibleFeatures, setVisibleFeatures] = useState(initialVisibleFeatures);
  const allFeatures = [

    {
      title: "Gold Backing",
      content: "Digital Gold units are <strong>100% backed by physical gold </strong> in the form of Standardized <strong>1 kg bar of 999.9 purity</strong>. from internationally recognized refineries.",
    },
    {
      title: "Unit Size and Pricing",
      content: "Each Digital Gold represents <strong>1 gram of gold of 999.9 purity</strong>, priced at the prevailing <strong>international gold rate</strong>.",
    },
    {
      title: "Fungibility & Liquidity",
      content: "Digital Gold are held in digital form and is available for trading on <strong> ComTech Gold App </strong>, making these units highly liquid & fungible. Investors can buy as low as <strong>0.50 grams</strong>.",
    },
    {
      title: "Shariah Compliant – Fatwa Certification",
      content: "ComTech Gold is the first in <strong>MENA</strong> to be awarded a <strong>Shariah Compliant Fatwa Certification</strong>. The Shariah Fatwa certificate was issued by <strong>Amanie Advisors Ltd</strong>, an entity specializing in the field of Shariah-compliant investments & Islamic finance solutions. Being Shariah Compliant ensures that Digital Gold is fully backed by physical gold which is identifiable and segregated. Every Purchase and Sale of gold shall result in the actual delivery from the Seller to the Buyer obeying Shariah Compliance.",
    },
    {
      title: "DMCC Tradeflow Warrant",
      content: "<strong>Dubai Multi Commodities Centre (DMCC)</strong> has partnered with ComTech Gold to digitalize the trading of gold using DMCC Tradeflow Warrant. In line with international best practices for transparency, each gold bar will be backed by a Tradeflow warrant and will feature unique ID numbers and certificates direct from the refiners, thereby providing additional security, transparency, and real-asset allocation.",
    },
    {
      title: "CONVERSION OF DIGITAL GOLD TO PHYSICAL GOLD",
      content: "Holders of Digital Gold units will have the facility to convert their units into Physical 1 Kg Gold bars. An investor holding 1000 units can request for cancellation of their unit in exchange for physical 1 kg Gold Bars. Shortly, we are also introducing smaller denominations – (5gm, 10gm, 50gm, 100gm & 500 gm) where an investor can redeem them into smaller bars or exchange them into any form of physical gold with our Trusted Jewellery Partner <strong>Kalyan Jewellers.</strong></br><a href='https://www.kalyanjewellers.net/store-locator.php' target='_blank'>https://www.kalyanjewellers.net/store-locator.php</a>",
    },
    {
      title: "Vaulting and Storage",
      content: "Physical gold bars are stored in UAE, in internationally accepted vaults.",
    },
    {
      title: "Zero Custodian Fees",
      content: "ComTech Gold charges Zero custodian fees. Investors thus save on NIL storage cost & is free from risk of theft, hassles of transportation as the gold in custody is <strong>Secured and Insured</strong>.",
    },
    {
      title: "Instant Settlement",
      content: "All trades are settled instantly, and the investor account is debited and credited with Funds/Digital Gold units instantly.",
    },
    

  ];

  const toggleFeatures = () => {
    if (visibleFeatures === initialVisibleFeatures) {
      setVisibleFeatures(allFeatures.length);
    } else {
      setVisibleFeatures(initialVisibleFeatures);
    }
  };
  const isSeeMore = visibleFeatures === initialVisibleFeatures;
  return (
    <div>
      <main>
        {/* <div  > */}

       
        <section className=" pt-5 mt-5 mb-5 bg-primary-light" id="Features">
          <div className="container mt-5">
            <div className="row">
              <div className="col-lg-12 mb-4 mt-2">
                <div className="main_title ">
                  <h2 className="h2 text-primary  text-center" >Features</h2>
                </div>
              </div>

              <div className="col-12">
                {allFeatures.slice(0, visibleFeatures).map((feature, index) => (
                  <div className="feature_wrap feat_digi bg-secondary-light" key={index}>
                    <div className="feature_icon">
                      <img src="assets/images/gold-backing.png" alt="gold-backing" />
                    </div>
                    <div className="feature_content">
                      <h5 className="h5">{feature.title}</h5>
                      <p dangerouslySetInnerHTML={{ __html: feature.content }}></p>
                    </div>
                  </div>
                ))}
                <div className="read_more_btn d-flex align-items-center justify-content-center mt-5">
                  <button className="btn btn-primary d-flex align-items-center" onClick={toggleFeatures}>
                    {isSeeMore ? 'See More' : 'See Less'}
                    <img
                      src={`assets/images/${isSeeMore ? 'down_arrow.svg' : 'up-arrow.svg'}`}
                      className="ms-4"
                      alt={isSeeMore ? 'See More Icon' : 'See Less Icon'}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}
       

        {/* <section className="pt-5 pb-5 mt-5 bg-secondary-light tokenized_gold" id="Transparency">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mb-4">
              <div className="main_title">
                <h2 className="h2 text-primary">Transparency</h2>
                <h3 className="h3 text-secondary">We provide an unrivaled level of reserve transparency, so that the community can always be confident that their digital assets are fully backed by Physical GOLD.</h3>
                <ul className="text-center m-0 p-0">
                  <li><span>Quarterly audits by Custodian based in UAE</span></li>
                  <li><span>On-demand verification for an onboarded entity. </span></li>
                    <li><span>Subject to Service fees applicable and prior appointment is required.</span></li>
                </ul>
              </div>
            </div>

            <div className="col-12 mt-5 mb-4">
              <div className="row justify-content-center">
                <div className="col-lg-9 mb-4">
                  <div className="main_title">
                    <h2 className="h2 text-primary">CURRENT GOLD SUPPLY</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div className="col-lg-9">
                <div className="t-table_wrap bg-primary-light border-radius">

                  <ul className="t-body">
                    <li className="d-flex flex-wrap w-100">
                      <span>Digital Gold</span>
                      <span>3000</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Tokenized Gold</span>
                      <span>141,000</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Total</span>
                      <span>144,000</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

           <p className='text-center'>(check on <Link style={{color:"#EDB246"}} to="https://explorer.xinfin.network/tokens/xdc8f9920283470f52128bf11b0c14e798be704fd15"target='_blank'>XDC Network Explorer</Link>)</p>


        
           

          </div>
        </div>
      </section> */}
       <section class="bg-secondary-light tokenized_gold gold_pattern px-80" id="Transparency">
            <div class="container">
               <div class="row justify-content-center z-index-3">
                  <div class="col-lg-9 mb-4">
                     <div class="main_title">
                        <h2 class="h2 text-primary">Transparency</h2>
                        <h3 class="h3 text-secondary">We provide an unrivaled level of reserve transparency, so that the community can always be confident that their digital assets are fully backed by Physical GOLD.</h3>
                        <ul class="text-center m-0 p-0">
                           <li><span>Quarterly audits by Custodian based in UAE</span></li>
                           <li><span>On-demand verification for an onboarded entity. </span></li>
                           <li><span>Subject to Service fees applicable and prior appointment is required.</span></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col-12 mt-5 mb-4">
                     <div class="row justify-content-center">
                        <div class="col-lg-9 mb-3">
                           <div class="main_title">
                              <h2 class="h2 text-primary">CURRENT GOLD SUPPLY</h2>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                     <div class="col-lg-9">
                        <div class="t-table_wrap bg-primary-light border-radius">
                           <ul class="t-body">
                              <li class="d-flex flex-wrap w-100"><span>Digital Gold</span><span>14,000</span></li>
                              <li class="d-flex flex-wrap w-100"><span>Tokenized Gold</span><span>142,000</span></li>
                              <li class="d-flex flex-wrap w-100"><span>Total</span><span>156,000</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                   <p className='text-center'>(check on <Link style={{color:"#EDB246"}} to="https://explorer.xinfin.network/tokens/xdc8f9920283470f52128bf11b0c14e798be704fd15"target='_blank'>XDC Network Explorer</Link>)</p>

                  <div class="row justify-content-center mt-5">
                  <div class="col-lg-3 col-md-6 col-6 mb-4">
                     <div class="bg-primary-light supply_box h-100">
                        <div class="supply_icon text-center mb-3">
                            <img src="assets/images/pdf_icon-white.svg" class="iconL img-responsive"/>
            				<img src="assets/images/pdf_icon.svg" class="iconD img-responsive"/>
                        </div>
                        <div class="supply_content text-center">
                           <h5 class="mb-3">Smart Contract Audit Report</h5>
                           <p>Explore Smart Contract Code at XDC Network explorer.</p>
                           <h6 class="mb-3">OCTOBER 6, 2023</h6>
                        </div>
                        <div class="supply_button text-center">
                        <Link to="/assets/pdf/ComtechGold_New_Audit.pdf" download target='_blank' className="btn btn-outline-primary">Download</Link>
</div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 mb-4">
                     <div class="bg-primary-light supply_box h-100">
                        <div class="supply_icon text-center mb-3">
                        	<img src="assets/images/edit_icon-white.svg" class="iconL img-responsive"/>
            				<img src="assets/images/edit_icon.svg" class="iconD img-responsive"/>
                        </div>
                        <div class="supply_content text-center">
                           <h5 class="mb-3">On-demand verification</h5>
                           <p>On-demand verification of Gold reserve balances and accounts, provided by a UAE based custodian.</p>
                        </div>
                        <div class="supply_button text-center"><a class="btn btn-outline-primary" href="/Contactus">Request Now</a></div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 mb-4">
                     <div class="bg-primary-light supply_box h-100">
                        <div class="supply_icon text-center mb-3">
                        	<img src="assets/images/pdf_icon-white.svg" class="iconL img-responsive"/>
            				<img src="assets/images/pdf_icon.svg" class="iconD img-responsive"/>
                        </div>
                        <div class="supply_content text-center">
                           <h5 class="mb-3">Routine audit</h5>
                           <p>Regular Audit report from custodian.</p>
                           <h6 class="mb-3">March 08, 2023</h6>
                        </div>
                        <div class="supply_button text-center"><a class="btn btn-outline-primary" href="/Routine">Download</a></div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 mb-4">
                     <div class="bg-primary-light supply_box h-100">
                        <div class="supply_icon text-center mb-3">
                        	<img src="assets/images/pdf_icon-white.svg" class="iconL img-responsive"/>
            				<img src="assets/images/pdf_icon.svg" class="iconD img-responsive"/>
                        </div>
                        <div class="supply_content text-center">
                           <h5 class="mb-3">Shariah Certification</h5>
                           <p>Explore Smart Contract Code at XDC Network explorer.</p>
                           <h6 class="mb-3">13 August 2024</h6>
                        </div> 
                        <div class="supply_button text-center">
                        <Link to="/assets/pdf/ComTech_Gold_Token_Shariah_Compliance_Cert.pdf" download target='_blank' className="btn btn-outline-primary">Download</Link>
                   </div>
                     </div>
                  </div>
                  </div>
                  
               </div>
            </div>
         </section>
{/*         
        <section className="" id="Feestructure">
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5 mb-5">
                <div className="main_title">
                  <h2 className="h2 text-primary">Fee Structure</h2>
                  <h3 className="h3 text-secondary">ComTech Gold Fee Structure.</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="t-table_wrap">
                  <ul className="t-head d-flex flex-wrap mb-2">
                    <li>Details</li>
                    <li>Fees</li>
                  </ul>
                  <ul className="t-body">
                    <li className="d-flex flex-wrap w-100">
                      <span>Transaction Fees</span>
                      <span>Nil</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Custody Fees</span>
                      <span>Nil</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Insurance Fees</span>
                      <span>Nil</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Transfer Fees</span>
                      <span>0.50%</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Gifting Fees</span>
                      <span>(Coming soon)</span>
                    </li>
                    <li className="d-flex flex-wrap w-100">
                      <span>Physical Gold Redemption Fees</span>
                      <span>0.50%</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}


<section class="bg-secondary-light tokenized_gold gold_pattern pt-5 px-80" id="Feestructure">
            <div class="container mt-5">
               <div class="row justify-content-center z-index-3">
                  
                  <div class="col-12 mb-4">
                     <div class="row justify-content-center">
                        <div class="col-lg-9 mb-3">
                           <div class="main_title">
                              <h2 class="h2 text-primary">Fee Structure</h2>
                              <h3 class="h3 text-secondary">ComTech Gold (CGO) Fee Structure.</h3>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row justify-content-center mb-3">
                     <div class="col-lg-9">
                        <div class="t-table_wrap bg-primary-light border-radius">
                <ul class="t-head d-flex flex-wrap mb-2">
                  <li class="text-primary">Details</li>
                  <li class="text-primary">Fees</li>
                </ul>
                <ul class="t-body">
                  <li class="d-flex flex-wrap w-100">
                    <span>Transaction Fees</span>
                    <span>Nil</span>
                  </li>
                  <li class="d-flex flex-wrap w-100">
                    <span>Custody Fees</span>
                    <span>Nil</span>
                  </li>
                  <li class="d-flex flex-wrap w-100">
                    <span>Insurance Fees</span>
                    <span>Nil</span>
                  </li>
                  <li class="d-flex flex-wrap w-100">
                    <span>Transfer Fees</span>
                    <span>0.50%</span>
                  </li>
                  <li class="d-flex flex-wrap w-100">
                    <span>Gifting Fees</span>
                    <span>(Coming soon)</span>
                  </li>
                  <li class="d-flex flex-wrap w-100">
                    <span>Physical Gold Redemption Fees</span>
                    <span>0.50%</span>
                  </li>
                </ul>
              </div>
                     </div>
                  </div>
                  
                  
                  
                  
               </div>
            </div>
         </section>

        <section className="" id="FAQs">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 mt-5 mb-5">
                <div className="main_title">
                  <h2 className="h2 text-primary text-center">FAQs</h2>
                </div>
              </div>

              <div className="col-12">


                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What is Digital Gold?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Digital Gold is the modern way of buying physically backed Gold through online channels without actually holding physical Gold. For an investor looking to purchase Gold, it is a more convenient, safe, and cost-effective option. For every gram of Gold you buy, there is actual 999.9 purity Gold stored in a secured vault. With a single click of a button on the app, you may purchase, or sell without having to go anywhere. This helps solve various issues related to traditional physical gold in relation to physical Gold extra premium, storage, fungibility and safety.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What is ComTech Digital Gold?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>ComTech Digital Gold is an organised and transparent method of buying 24 carat gold in compliance with all applicable laws and regulations. ComTech Gold  has combined the benefits of gold with the advantages of blockchain allowing a global user to invest in Gold. You can invest in gold through purchasing ComTech  Digital Gold according to your wish and have complete freedom and flexibility.  ComTech Gold is 100% backed by actual physical Gold stored with Transguard in a complete insurance and segregation. ComTech  Digital Gold also provides you with the flexibility to convert your Digital Gold to actual physical 24 Karat Gold of 999.9 fineness. Since it is not physically possible to deliver a fractional quantity of gold, minimum quantity for which the delivery is proposed to be made has been kept as low as 10 gram and thereafter in multiples of 1 gram.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      What is the Purity of Gold offered by ComTech Digital Gold?
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>ComTech Gold offers 24 Karat Gold of 999.9 fineness (99.99% pure).</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      Is ComTech  audited?
                      </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      <p>Yes. We are regularly subjected to periodic independent third-party audit where our financials, financial reporting, operations, compliance, and information security, amongst other processes are routinely audited. This offers a level of assurance for partners, investors & financial institutions to trust us and work directly with us.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Is ComTech Gold regulated? What does that mean?
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Yes, ComTech Gold is regulated by Dubai Airport Free Zone Authority (DAFZA) and endorsed by local UAE government body DMCC. ComTech Gold is subjected to a higher level of regulatory oversight, ensuring all our customers’ funds have the highest form of protection. We strictly follow the regulatory guidelines towards the capital reserve, consumer protection, compliance, and anti-money laundering requirements. Our customers receive the highest level of security and protection for the assets they deposit with us.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Why buy ComTech Gold?
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p><strong>Safety</strong> - Consistent gold rate – our gold price is pegged to international price. 100% secured as all gold backing the Digital Gold is strored  in a secured & insured TransGuard Safe vaults.</p>
                        <br /><p><strong>Convenience</strong> :  Buy as low as 0.5 gm of 999.9 purity digitally at the convenience of your home. Travel across the globe with Gold in your wallet with a convenience to sell during market hours.
                        </p>
                        <br /><p><strong>Sensible</strong> : Save on Storage, Insurance Costs. Hedge against the uncertain market by investing in purest form of gold.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Where to buy ComTech Digital Gold?
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>One can download ComTech gold App from the playstore on their mobile or access from our website and start investing.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      What is needed to open an account?
                      </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Retail Customers are required to provide us with the requisite KYC documents and enter their basic personal information. You may be requested to submit additional identification documents to us to proceed with the account verification process.</p>
                       <br/> <p>Institutional investors are also required to submit the KYC documents including but not restricted to proof of beneficial owners’ identification and residence as well as requisite corporate documents for account verification purposes upon signing up.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      What will happen to the gold associated with accumulations in my account, in the unlikely event of the ComTech Gold going into liquidation?
                      </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The gold associated with the accumulations in your account is separate from the other assets of the ComTech Gold. The purchased gold is securely stored and insured with Regulated Vault like TransGuard an Emirates Group Company in UAE. Every movement of Gold is endorsed and approved by local UAE government body DMCC. When you ask for converting your 1000 tokens into a bar of gold, the requisite quantity of gold will be removed from the Custodian TransGuard and delivered to you. As every movement of Gold is endorsed and approved by local UAE government body DMCC, in any unlikely adverse event happening to Company, the gold associated with the accumulations in your account will not be affected.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                      Who will insure the physical vault?
                      </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The secure storage provider (TransGuard, Brinks) has a comprehensive, global insurance policy that includes the insurance cover on your gold stored in their vaults. </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                      What is the minimum & maximum quantity?
                      </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The minimum quantity one can purchase is as low as 0.5 gm and there is no limit on the maximum.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwelve">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                      How do I sell my gold?
                      </button>
                    </h2>
                    <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>ComTech Gold provides a sell price quote on their ComTech Gold app during international market hours. You can choose to sell any amount to a maximum of the amount of gold that you own. </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                      At what price is gold sold/ bought from customers?
                      </button>
                    </h2>
                    <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The ComTech Digital Gold price is pegged to International Gold Price and ComTech Gold will publish a live buy-sell quote for you which will be derived from the same plus a nominal commission to cover operating costs. The actual purchase price paid by you will always be transparently published so that you have a chance to compare with all other sources before entering a transaction.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFourteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                      Where can I find the invoice for each order?
                      </button>
                    </h2>
                    <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>An invoice shall be emailed to you as an attachment after each successful transaction. It is also available in the Transaction history section of the ComTech Gold app.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFifteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                      How can I get my holding statement?
                      </button>
                    </h2>
                    <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>An online statement will be always available in the ComTech Gold app.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSixteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                      Is the gold associated with my accumulations kept in safe custody?
                      </button>
                    </h2>
                    <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The gold purchased on your behalf is stored with TransGuard and Brinks, a leading safe keeper of precious metals across the world. The gold is insured, both for storage and transit when being delivered to you. Further, DMCC endorses all gold stored in the vault, this further ensures that your gold is protected at all times regardless of any external events.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeventeen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                      What is the maximum period of time for which my gold can be stored with ComTech Gold?
                      </button>
                    </h2>
                    <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingSeventeen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>You can store your gold with ComTech Gold for any period of time.. The custody shall be without charge for the first 24 months, and thereafter, will continue to be stored by ComTech Gold for a nominal fee to cover storage costs. You will be intimated in advance of being charged the fee and will have the option to sell or request delivery of your gold at all times. </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEighteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                      Is there any lock-in period for this plan?
                      </button>
                    </h2>
                    <div id="collapseEighteen" className="accordion-collapse collapse" aria-labelledby="headingEighteen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No, there is no lock-in period from the date of purchase.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNineteen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                      Can I have a joint account for this plan?
                      </button>
                    </h2>
                    <div id="collapseNineteen" className="accordion-collapse collapse" aria-labelledby="headingNineteen" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No, you cannot have a joint account for ComTech Gold.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwenty">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                      Can I cancel my sell order/request of gold sell?
                      </button>
                    </h2>
                    <div id="collapseTwenty" className="accordion-collapse collapse" aria-labelledby="headingTwenty" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No, you cannot cancel the order once it has been successfully placed on the ComTech Gold app.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyone">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyone" aria-expanded="false" aria-controls="collapseTwentyone">
                      What if I die during the tenure of the plan?
                      </button>
                    </h2>
                    <div id="collapseTwentyone" className="accordion-collapse collapse" aria-labelledby="headingTwentyone" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>In the event of your death, subject to the required due diligence, the title to your Gold lying in the vault and your account shall be transferred to your legal heir.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentytwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                      What are the steps to take if I feel like my account has been compromised?
                      </button>
                    </h2>
                    <div id="collapseTwentytwo" className="accordion-collapse collapse" aria-labelledby="headingTwentytwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>If you feel your account has been compromised, please reach out to us immediately.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                      How can I withdraw my gold?
                      </button>
                    </h2>
                    <div id="collapseTwentyThree" className="accordion-collapse collapse" aria-labelledby="headingTwentyThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>You can request delivery for all or part of your gold holding, starting from 10 gm, and multiples of 1 gram thereof. You can select the quantity and denomination of the gold you wish to withdraw from the available options of physical delivery. Each physical delivery option will have different making charges, based on the weight and design of the coin. You must confirm the transaction by paying the additional making and delivery charge. The coins will be delivered at your doorstep within UAE. Outside UAE the delivery will be to the nearest Airport and you would be expected to bring it out observing the required custom and other legal processes. The delivery can be tracked at any time by logging into your ComTech Gold wallet account.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                      Is it possible to store my withdrawn gold coins in safe custody and have them delivered later?
                      </button>
                    </h2>
                    <div id="collapseTwentyFour" className="accordion-collapse collapse" aria-labelledby="headingTwentyFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Once you place a delivery request, you have to accept the delivery made to you. It cannot be made at a later date.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                      Can I put in a different address from the one I used at the time of registration on ComTech Gold?
                      </button>
                    </h2>
                    <div id="collapseTwentyFive" className="accordion-collapse collapse" aria-labelledby="headingTwentyFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Yes. you can enter a different address; however, verification of the address change request shall be subject to ComTech Gold norms. In the event that the new delivery pin code does not fall in our serviceable area, you may have to enter different address.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentySix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                      Can I change my delivery address once I have placed a delivery request?
                      </button>
                    </h2>
                    <div id="collapseTwentySix" className="accordion-collapse collapse" aria-labelledby="headingTwentySix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No, your address on the delivery request, once accepted on the ComTech Gold platform, cannot be changed.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentySeven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
                      Can I cancel my purchase order/request of gold purchase?
                      </button>
                    </h2>
                    <div id="collapseTwentySeven" className="accordion-collapse collapse" aria-labelledby="headingTwentySeven" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No, you cannot cancel the purchase order of gold once it has been successfully placed on the ComTech Gold platform.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyEight">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
                      Can I cancel my delivery order/request of gold?
                      </button>
                    </h2>
                    <div id="collapseTwentyEight" className="accordion-collapse collapse" aria-labelledby="headingTwentyEight" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Once you place a delivery request, you must accept the delivery made to you. It cannot be cancelled or delivered at a later point in time.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyNine">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
                      Can someone else redeem the physical gold on my behalf?
                      </button>
                    </h2>
                    <div id="collapseTwentyNine" className="accordion-collapse collapse" aria-labelledby="headingTwentyNine" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No, only you can request for the delivery of physical coins from your Gold balance. However, in the event of your death, your legal heir will be able to collect the gold by showing their proof of identity along with the original / notarized copy of the death certificate and proof that the person is your legal heir.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirty">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
                      How can I be assured that the gold coins delivered by you are genuine and trust worthy?
                      </button>
                    </h2>
                    <div id="collapseThirty" className="accordion-collapse collapse" aria-labelledby="headingThirty" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>All our coins are certified and hallmarked to certify purity and delivered in tamper proof packaging and are dispatched directly from our premises or through the authorised bullion/Jeweller location. The coins are also insured through transit to ensure your gold is in safe hands right from the locker to your home. However, in case of any discrepancy, please get in touch with us. </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtyone">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyone" aria-expanded="false" aria-controls="collapseThirtyone">
                      What happens to my gold if I deactivate or close my ComTech Gold account with Gold balance present?
                      </button>
                    </h2>
                    <div id="collapseThirtyone" className="accordion-collapse collapse" aria-labelledby="headingThirtyone" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>At the time of deactivation, you will be offered the option to withdraw your Gold balance or request for delivery of your gold. Please ensure that your gold balance is nil in order to deactivate or close your ComTech Gold account. </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtytwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtytwo" aria-expanded="false" aria-controls="collapseThirtytwo">
                      I have some queries regarding my gold account, how can I get in touch?
                      </button>
                    </h2>
                    <div id="collapseThirtytwo" className="accordion-collapse collapse" aria-labelledby="headingThirtytwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Please raise a ticket or get in touch with ComTech Gold customer service centre, and we’ll respond to any queries you have on your gold account. ComTech Gold team is available to provide the first level of support and ComTech Gold shall be available to address any questions/issues that may be escalated.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtythree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtythree" aria-expanded="false" aria-controls="collapseThirtythree">
                      Who is responsible for the security of my account information?
                      </button>
                    </h2>
                    <div id="collapseThirtythree" className="accordion-collapse collapse" aria-labelledby="headingThirtythree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Your gold balance is accessible through your ComTech Gold account. You are responsible for the security of your username and password, and ComTech Gold shall not be liable for any losses or liabilities due to breach of your ComTech Gold account as a result of your incorrectly sharing your username and password. Please do not disclose your password to anyone verbally or in writing or record it elsewhere. Our team / service representatives will never ask you for your password, pin or OTP.</p>
                      </div>
                    </div>
                  </div>

                 
                </div>

              </div>
            </div>
          </div>
        </section>


      </main>
    </div>
  )
}

export default Digitalgold