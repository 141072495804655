import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext'; 

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTransparency = async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('Transparency');
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');
      if (goldHeading) {
        goldHeading.style.marginTop = '50px';
      }
      transparencySection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const scrollToFeatures = async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('Features');
    
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');
      // if (goldHeading) {
      //   goldHeading.style.paddingTop = '100px';
      // }
  
      transparencySection.scrollIntoView({ behavior: "smooth" });
      
      setIsMenuOpen(false);
      window.scrollBy(0, -10000);
      
    }
  };
  

  const scrollToFeestructure = async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('Feestructure');
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');
  
      if (goldHeading) {
        goldHeading.style.marginTop = '50px';
      }
      transparencySection.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  const scrollToFAQs = async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('FAQs');
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');
  
      if (goldHeading) {
        goldHeading.style.marginTop = '50px';
      }
      transparencySection.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMenuOpen]);



  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme;
    } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      return 'lightTheme';
    } else {
      return 'lightTheme';
    }
  };

  // State to manage the current theme
  const [theme, setTheme] = useState(getInitialTheme);

console.log('theme', theme)
  // Function to toggle between dark and light themes
  const toggleTheme = () => {
    const newTheme = theme === 'darkTheme' ? 'lightTheme' : 'darkTheme';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    
  };

  // Effect to update the body class when the theme changes
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);


  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const handleDropdownOpen1 = () => {
    setIsDropdownOpen1(true);
  };
  
  const handleDropdownClose1 = () => {
    setIsDropdownOpen1(false);
  };
  
  const handleDropdownClose2 = () => {
    setIsDropdownOpen2(false);
  };
  
  const handleDropdownOpen2 = () => {
    setIsDropdownOpen2(true);
  };

  return (
    <header className={`header menu-sticky sticky bg-primary-dark ${isMenuOpen ? 'active' : ''}`}>
      <nav className="container navbar navbar-expand-lg">
        <Link to="/" className="navbar-brand">
          {/* <img src="assets/images/logo.png" alt="logo" /> */}
          <img src="assets/images/logo-white.svg" className='iconL' />
          <img src="assets/images/logo.svg" className='iconD' />
        </Link>

        <button
          type="button"
          onClick={toggleMenu}
          className={`navbar-toggler navbar-toggler-right ${isMenuOpen ? 'active' : ''}`}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div id="navbarSupportedContent" className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto align-items-start align-items-lg-center">
            <li className={`nav-item ms-0 me-0 ms-lg-3 me-lg-3 ${location.pathname === '/' ? 'active' : ''}`}>
              <Link to="/" className="nav-link">Home</Link>
            </li>

            <li className={`nav-item dropdown ms-0 me-0 ms-lg-3 me-lg-3 ${location.pathname === '/Digitalgold' ? 'active' : ''}`}
            onMouseEnter={handleDropdownOpen} onMouseLeave={handleDropdownClose}>
 
              <Link to="/Digitalgold" className="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded={isDropdownOpen ? "true": "false"}>
                Digital Gold
              </Link>
 
              <ul className={`dropdown-menu ${isDropdownOpen ? "show": ""}`} aria-labelledby="navbarDropdown">
                <li><Link to="/Digitalgold" onClick={scrollToFeatures} className="links" >Features</Link></li>
                <li><Link to="/Digitalgold" onClick={scrollToTransparency} className="links">Transparency</Link></li>

                <li><Link to="/Digitalgold" onClick={scrollToFeestructure} className="links">Fee Structure</Link></li>
                <li><Link to="/Digitalgold" onClick={scrollToFAQs} className="links">FAQs</Link></li>
              </ul>
            </li>

            <li className={`nav-item dropdown ms-0 me-0 ms-lg-3 me-lg-3 ${location.pathname === '/TokenizedGold' || location.pathname === '/Cgolistinglibrary' || location.pathname === '/Feestructure' || location.pathname === '/Deriskfromstablecoins' || location.pathname === '/Transparency' || location.pathname === '/Exchanges&Wallets' || location.pathname === '/faqs' ? 'active' : ''}`}
             onMouseEnter={handleDropdownOpen1} onMouseLeave={handleDropdownClose1}>
              <Link className="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded={isDropdownOpen1 ? "true": "false"}>
                Tokenized Gold
              </Link>
              <ul className={`dropdown-menu ${isDropdownOpen1 ? "show": ""}`} aria-labelledby="navbarDropdown">
                <li><Link to="/TokenizedGold" className="links">Features</Link></li>
                <li><Link to="/Cgolistinglibrary" className="links">CGO Listing Library</Link></li>
                <li><Link to="/Feestructure" className="links">Fee Structure</Link></li>
                <li><Link to="/Deriskfromstablecoins" className="links">De- Risk from Stablecoins</Link></li>
                <li><Link to="/Transparency" className="links">Transparency</Link></li>
                <li><Link to="/Exchanges&Wallets" className="links">Exchanges & Wallets</Link></li>
                <li><Link to="/faqs" className="links">FAQs</Link></li>
              </ul>
            </li>

            <li className={`nav-item dropdown ms-0 me-0 ms-lg-3 me-lg-3 ${location.pathname === '/Aboutus' || location.pathname ==='/Blogs' ? 'active' : ''}`}
            onMouseEnter={handleDropdownOpen2} onMouseLeave={handleDropdownClose2}>
              <Link to="/Aboutus" className="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded={isDropdownOpen2 ? "true": "false"}>
              About Us
              </Link>
              <ul className={`dropdown-menu ${isDropdownOpen2 ? "show": ""}`} aria-labelledby="navbarDropdown">
                <li><Link to="/Aboutus" className="links">About Us</Link></li>
                <li><Link to="/Blogs" className="links">Blogs</Link></li>
                <li><Link  className=" links">Media</Link></li>
           </ul>
            </li>

            <li className={`nav-item ms-0 me-0 ms-lg-3 me-lg-3 ${location.pathname === '/Contactus' ? 'active' : ''}`}>
              <Link to="/Contactus" className="nav-link">Contact Us</Link>
            </li>

              <li className={`nav-item dark-icon ms-0 me-0 ms-lg-3 me-lg-3`}>
            <Link onClick={toggleTheme} className="nav-link">
              {theme === 'darkTheme' ?  <i className=" icon fas fa-sun mt-2" style={{fontSize:"22px"}} ></i> : <i className=" icon fas fa-moon mt-2" style={{fontSize:"22px"}} ></i>}
            </Link>
          </li>


          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
